import { useState } from 'react'
import { Button } from '../../../components';
import { authStaff } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { useNavigate } from 'react-router-dom';
import { show } from '../../../slices/toastSlice';

function PreApproval({ loan, setConfirmedClient }) {

    const accessToken = useSelector(selectAccessToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [client, setClient] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [approvalProcessing, setApprovalProcessing] = useState(false);
    const [confirmApproval, setConfirmApproval] = useState(false);
    const [counter, setCounter] = useState(30);
    const [success, setSuccess] = useState(null);

    const verifyClient = async() => {
        setProcessing(true);
        
        let data = {
            phone : loan?.phone,
            network : "mtn",
        }

        let url = `/payments/staff/verify-client`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "GET", headers, data, {});
        setProcessing(false);

        if(res?.codee === 401){
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        setClient(res);
        setSuccess(true);
    }


    const requestApproval = async() => {
        setApprovalProcessing(true);

        let data = {
            phone : loan?.phone,
            network : "mtn",
            amount : loan?.accountInfo?.premium
        }

        let url = `/payments/staff/request-approval`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setApprovalProcessing(false);

        if(res?.code === 401){
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        setConfirmApproval(true);

        // timer to enable verify button after 30 seconds
        const startTimer = () => {
            const timer = setInterval(() => {
                setCounter(prevCounter => prevCounter - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        };
        
        if(counter > 0){
            startTimer();
        }

        // check for approval status after 30 seconds
        setTimeout(() => {
            checkApprovalStatus(res?.referenceId, res?.token);
        }, 30000)
    }

    const checkApprovalStatus = async(referenceId, token) => {
        let url = `/payments/staff/check-approval-status`;
        let headers = authStaff(accessToken);
        let data = {
            referenceId : referenceId,
            token, token
        }

        let res = await fetchRequests(url, "GET", headers, data, {});

        if(res?.code === 401){
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        if(res?.status === "CREATED"){
            setCounter(30);
            setTimeout(() => {
                checkApprovalStatus(referenceId, token);
            }, 30000);
            return;
        }

        if(res?.status === "FAILED"){
            setCounter(30);
            dispatch(show({
                type : "error",
                state : true,
                message : `Client did not approve the request to auto-debit their account.`
            }))
            return;
        }

        client.approval = `Client approved the request to auto-debit their account. Fund disbursement can proceed.`;

        console.log(res);
    }


    return (
        <div className='p-1 flex flex-col'>
            <div className='bg-yellow-200 p-4 rounded-md text-yellow-700'>
                Verify client's details with Telco <span className='font-bold'>(MTN)</span> and confirm client's approval to proceed with disbursement
            </div>

            <div className='inline-flex items-center w-full space-x-4 mt-4'>
                <Button 
                    btnText={"Verify Client"}
                    btnType="button"
                    btnClasses={"form-button w-32"}
                    disabled={processing}
                    btnFunction={verifyClient}
                    processing={processing}
                />

                {success && (
                    <>
                    <Button 
                        btnText={`Request Approval`}
                        btnType="button"
                        btnClasses={"cancel-button w-52"}
                        disabled={!client || approvalProcessing}
                        btnFunction={requestApproval}
                        processing={approvalProcessing}
                    />

                    {(confirmApproval && counter > 0) && (
                        <p className='ml-3'>
                            Checking the status of the client's approval in <span className='font-bold'>{counter}</span> seconds...
                        </p>
                    )}
                    </>   
                )}
            </div>

            <div className='mt-8'>
                <h5 className="font-bold mb-3 underline">
                    Details from Telco (MTN)
                </h5>
                <ul>
                    <li>
                        Full name : 
                        <span className='font-bold'>
                            &nbsp;{`${client?.given_name} ${client?.family_name}`}
                        </span>
                    </li>
                    {/* <li>
                        Date of birth : 
                        <span className='font-bold'>
                            &nbsp;{client && dateToHumanFormat(client?.birthdate)}
                        </span>
                    </li> */}
                    <li>
                        Status of approval : 
                        <span className='font-bold'>
                            &nbsp;{client?.approval}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PreApproval